@use '@angular/material' as mat;
@use 'src/scss/palette' as pal;
@import 'screen';

@mixin stylize-text($font-size, $font-weight, $line-height) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

/***
  Flex shortcuts
 */
@mixin column() {
  display: flex;
  flex-direction: column;
}

@mixin column-sm() {
  @include sm {
    flex-direction: column !important;
  }
}

@mixin row() {
  display: flex;
  flex-direction: row;
}

@mixin fill-vertical-horizontal() {
  width: 100%;
  height: 100%;
}

@mixin fill-xs {
  @include xs {
    width: 100%;
  }
}

@mixin fill-sm {
  @include sm {
    width: 100%;
  }
}

// Vertical and horizontal centering
@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin horizontal-start() {
  display: flex;
  justify-content: flex-start;
}

@mixin horizontal-end() {
  display: flex;
  justify-content: flex-end;
}

@mixin horizontal-space-between() {
  display: flex;
  justify-content: space-between;
}

@mixin horizontal-center() {
  display: flex;
  justify-content: center;
}

@mixin vertical-center() {
  display: flex;
  align-items: center;
}

@mixin vertical-end() {
  display: flex;
  align-items: flex-end;
}

@mixin vertical-start() {
  display: flex;
  align-items: flex-start;
}

/***
  Gap shortcuts
  Used to standardize the use of given gaps in the app according to the standardized design
 */
@mixin gap-lg() {
  gap: 1.5rem;
}

@mixin gap-ml() {
  gap: 1.25rem;
}

@mixin gap() {
  gap: 1rem;
}

@mixin gap-sm() {
  gap: 0.5rem;
}

@mixin gap-xs() {
  gap: 0.25rem;
}

/***
  * Padding shortcuts
  * Used to standardize the use of given paddings in the app according to the standardized design
  * In most cases, you achieve design guidelines by using these shortcuts
 */
@mixin padding-xxs() {
  padding: 0.25rem; // Equivalent to 4px
}

@mixin padding-xs() {
  padding: 0.5rem; // Equivalent to 8px
}

@mixin padding-sm() {
  padding: 1.25rem; // Equivalent to 20px
}

@mixin padding() {
  padding: 2rem; // Equivalent to 32px
}

@mixin padding-lg() {
  padding: 3rem; // Equivalent to 48px
}

@mixin padding-xl() {
  padding: 4rem; // Equivalent to 64px
}

/***
  * Standard container padding
  * Applies certain paddings to the container according to the screen size
 */
@mixin default-container-padding($vertical-padding: 2rem) {
  padding: $vertical-padding 2rem;

  @include xxl {
    padding: $vertical-padding 10rem;
  }

  @include xl {
    padding: $vertical-padding 7rem;
  }

  @include lg {
    padding: $vertical-padding 3rem;
  }

  @include sm {
    padding: $vertical-padding 1rem;
  }

  @include xs {
    padding: $vertical-padding 0.5rem;
  }
}

// Padding for main content in operator views
@mixin main-padding($padding: 1rem, $main-lateral-padding: 3.75rem) {
  padding: $padding $main-lateral-padding;
}

/***
  * Standard container border radius
  * Created to reuse the same border in most of the containers
 */

// Used in bigger containers
@mixin container-border-radius {
  border-radius: 2rem;
}

// Used in smaller components, e.g. small info cards
@mixin container-border-radius-sm {
  border-radius: 1rem;
}

// Used in smallest components, e.g. small items in paginator
@mixin container-border-radius-xs {
  border-radius: 0.5rem;
}

/***
  * Reuse the same shadows in most of the components
 */
@mixin shadow-1() {
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.08) !important;
}

@mixin shadow-2() {
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.17);
}

/***
  * Font utils
  * Main advantage is being used directly in the HTML without the need
  * to create a class only for apply font styles.
 */

@mixin heavy() {
  font-family: AspiraHeavy, sans-serif;
}

@mixin demi() {
  font-family: AspiraDemi, sans-serif;
}

@mixin bold() {
  font-family: AspiraHeavy, sans-serif;
}

@mixin semi-bold() {
  font-family: AspiraDemi, sans-serif;
}

@mixin italic() {
  font-style: italic;
}

@mixin medium() {
  font-weight: 500;
}

@mixin normal-weight() {
  font-weight: normal;
}

@mixin text-xxl() {
  font-size: 3.125rem;
  line-height: 3.75rem;
}

@mixin text-xl() {
  font-size: 1.375rem;
  line-height: 1.65rem;
}

@mixin text-lg() {
  font-size: 1.125rem;
  line-height: 1.375rem;
}

@mixin text-md() {
  font-size: 1rem;
  line-height: 1.25rem;
}

@mixin text-sm() {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@mixin text-xs() {
  font-size: 0.75rem;
  line-height: 1rem;
}

@mixin justify() {
  text-align: justify;
}

/***
  * Palette utils
  * Avoid using mat functions when there are simpler attributes to use
 */

@mixin primary-color($color: 800) {
  color: mat.m2-get-color-from-palette(pal.$primary-palette, $color);
}

@mixin secondary-color($color: 500) {
  color: mat.m2-get-color-from-palette(pal.$secondary-palette, $color);
}

@mixin tertiary-color($color: 500) {
  color: mat.m2-get-color-from-palette(pal.$tertiary-palette, $color);
}

@mixin grey-color($color: 500) {
  color: mat.m2-get-color-from-palette(pal.$grey-palette, $color);
}

@mixin error-color($color: 600) {
  color: mat.m2-get-color-from-palette(pal.$error-palette, $color);
}

@mixin success-color($color: 500) {
  color: mat.m2-get-color-from-palette(pal.$success-palette, $color);
}

@mixin warn-color($color: 500) {
  color: mat.m2-get-color-from-palette(pal.$pending-palette, $color);
}

@mixin white-color() {
  color: white;
}

@mixin black-color() {
  color: black;
}

@mixin primary-background($color: 100) {
  background-color: mat.m2-get-color-from-palette(pal.$primary-palette, $color);
}

@mixin secondary-background($color: 100) {
  background-color: mat.m2-get-color-from-palette(pal.$secondary-palette, $color);
}

@mixin tertiary-background($color: 500) {
  background-color: mat.m2-get-color-from-palette(pal.$tertiary-palette, $color);
}

@mixin grey-background($color: 100) {
  background-color: mat.m2-get-color-from-palette(pal.$grey-palette, $color);
}

@mixin warn-background($color: 100) {
  background-color: mat.m2-get-color-from-palette(pal.$pending-palette, $color);
}

@mixin success-background($color: 100) {
  background-color: mat.m2-get-color-from-palette(pal.$success-palette, $color);
}

@mixin error-background($color: 100) {
  background-color: mat.m2-get-color-from-palette(pal.$error-palette, $color);
}

@mixin white-background() {
  background-color: white;
}

@mixin opacity-background($color: 100, $opacity: 0.3) {
  background-color: mat.m2-get-color-from-palette(pal.$primary-palette, $color, $opacity);
}

@mixin client-background() {
  background:
    linear-gradient(0deg, rgba(0, 36, 112, 0.5) 0%, rgba(0, 36, 112, 0.5) 100%),
    url('/assets/images/road_background.jpeg'),
    lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100vh;
}

@mixin home-background() {
  background: linear-gradient(180deg, rgb(0, 51, 161) 0%, rgb(255, 255, 255) 100%);
}

@mixin road-black-background() {
  background-image: url('/assets/images/road_background.jpeg');
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  display: flex;
  height: 100vh;
}

@mixin road-blue-background() {
  background:
    linear-gradient(0deg, rgba(0, 36, 112, 0.65) 0%, rgba(0, 36, 112, 0.65) 100%),
    url('/assets/images/road_background.jpeg'),
    lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 100vh;
}

// Borders
@mixin primary-border($color: 800, $type: solid, $bold: 1px) {
  border: $bold $type mat.m2-get-color-from-palette(pal.$primary-palette, $color);
}

@mixin primary-outline($color: 800) {
  outline: 2px solid mat.m2-get-color-from-palette(pal.$primary-palette, $color);
}

@mixin secondary-border($color: 500, $type: solid, $bold: 1px) {
  border: $bold $type mat.m2-get-color-from-palette(pal.$secondary-palette, $color);
}

@mixin secondary-outline($color: 500) {
  outline: 2px solid mat.m2-get-color-from-palette(pal.$secondary-palette, $color);
}

@mixin grey-border($color: 800, $type: solid, $bold: 1px) {
  border: $bold $type mat.m2-get-color-from-palette(pal.$grey-palette, $color);
}

/***
  * Table helper mixins
 */

/*
  Used to apply styles to a specific NG dynamic table column
 */
@mixin ng-table-column($column-name) {
  :host ::ng-deep {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      &.mat-column-#{$column-name} {
        @content;
      }
    }
  }
}

/**
  Used to put ellipsis in an text if is bigger than $lines_number lines
 */
@mixin hide-text-with-ellipsis($height: 1.5rem, $lines_number: 2) {
  display: -webkit-box;
  max-height: $height;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines_number;
}

@mixin saving-changes {
  pointer-events: none !important;
  opacity: 0.7;
}

@mixin spinner() {
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin click-effect() {
  transform: scale(0.95);
  opacity: 0.8;
}

@mixin table-clickable-element() {
  ng-action-table {
    pointer-events: all;
  }
}

@mixin word-break() {
  word-break: break-word;
}
